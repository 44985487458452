export const messages = {
    en: {
        'reset-password': 'Reset Your Password',
        'create-your-password': 'Create Your Password',
        'change-password': 'Change Password',
        'create-password': 'Create Password',
        'new-password': 'New Password',
        'confirm-password': 'Confirm Password',
        'password-error': 'Password should be at least 8 digits!',
        'password-match-error': 'Passwords don\'t match',
        'success-label':'Success',
        'success-message':'Your new password has been set. You may use your new password now.',
        'sign-in':'Sign in',
        'return-to-main-page': 'Return to Main Page'
    }
};