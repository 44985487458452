import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { messages } from './helpers/messages';
import { alertActions } from './actions/alert.actions';
import { IntlProvider } from 'react-intl';
import Home from './home';

const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#69e781',
      },
      secondary: {
          main: '#ff3c5f',
      },
  },
  typography: {
      fontSize:13,
      fontFamily: [
          'Roboto',
          'Montserrat',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          '"Helvetica Neue"',
          'Montserrat',
      ].join(','),
  },
  overrides: {
      MuiOutlinedInput: {
          root: {
              backgroundColor: "white",
              "&$focused": {
                  backgroundColor: "white"
              }
          }
      }
  },
});

class App extends Component {
  constructor(props) {
      super(props);

      this.state = {
          lang: 'en',
      };
  }

  handleClose = () => {
      this.props.clearAlerts();
  };

  render() {
      const { alert } = this.props;
      const { lang } = this.state;

      return (
          <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Snackbar open={alert.message != null} anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }} autoHideDuration={6000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity={alert.type}>
                      {alert.message}
                  </Alert>
              </Snackbar>
              <IntlProvider locale={lang} messages={messages[lang]}>
                  <Home />
              </IntlProvider>
          </MuiThemeProvider>
      );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);

export { connectedApp as App };
