import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { history } from './helpers/history';
import PasswordReset from './components/password_reset';
import CreatePassword from './components/CreatePassword'
import NoMatch from './components/no_match';

const styles = theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },

});

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstActiveMenu: '',
            secondActiveMenu: '',
            openSwipeable: false
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root} >
                <Router history={history}>
                    <Switch>
                        <Redirect exact from="/" to="/user/reset/password/ui/:token?" />
                        <Route path="/user/reset/password/ui/:token?" component={PasswordReset} />
                        <Route path="/user/generate/password/ui/:token?" component={CreatePassword} />
                        <Route path="*" component={NoMatch} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default withStyles(styles)(
    injectIntl(
        (Home)
    )
);