import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import logoIcon from '../images/logo_white.png';
import notFound from '../images/404_Error.jpg';
import Container from '@material-ui/core/Container';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height:'100vh',
        backgroundColor: '#fff'
    },
    bar: {
        height: 80,
        width: '100%',
        backgroundColor: '#000',
        paddingTop: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    content:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        margin: 'auto',
        left: 0,
        bottom: 0,
        right: 0
    },
    img:{
        width:'100%'
    }
});

class NoMatch extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.bar}>
                    <img src={logoIcon} alt='TookFresh' height="45" />
                </div>
                <div className={classes.content}>
                    <Container maxWidth="sm" style={{height:'100%'}}>
                        <img src={notFound} alt='404' className={classes.img}/>
                    </Container>
                </div>
            </div >
        );
    }
}

export default withStyles(styles)(
    withRouter(
        injectIntl(
            NoMatch
        )
    )
);