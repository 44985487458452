import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import logoIcon from '../images/logo_white.png';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import visibleIcon from '../images/show.svg';
import visibleOff from '../images/show-off.svg';
import axios from 'axios';
import { alertActions } from '../actions/alert.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// const API_URL = process.env.REACT_APP_API_URL;

const API_URL = 'https://api.tookfresh.com';

const header = {
    headers: {
        'Content-Type': 'application/json'
    }
};
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: '#fff'
    },
    bar: {
        height: 80,
        width: '100%',
        backgroundColor: '#000',
        paddingTop: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    container: {
        flexGrow: 1,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5)
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#69e781'
    },
    button: {
        marginTop: theme.spacing(6),
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: 14,
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.29,
        padding: 8,
        width: '100%',
        height: 40,
        backgroundColor: '#69e781',
        '&:hover': {
            backgroundColor: '#69e781',
        },
    },
    routeButton: {
        textTransform: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: 8,
        color: theme.palette.primary.main,
        fontSize: '1.5em',
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
    route: {
        textDecoration: "none", 
        marginTop: theme.spacing(3),
    }
});

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirmPassword: '',
            passwordInvalid: true,
            confirmPasswordInvalid: true,
            showPassword: false,
            showConfirm: false,
            activeStep: 0
        }

        this.onFormSubmit = this.onFormSubmit.bind(this);

    }

    getLabel(labelId) {
        const { formatMessage } = this.props.intl;
        return formatMessage({ id: labelId });
    }

    handlePasswordChange = event => {
        this.setState({
            password: event.target.value,
            passwordFocused: true,
            passwordInvalid: event.target.value.length < 8,
            confirmPasswordInvalid: this.state.confirmPassword !== event.target.value
        });
    }

    handleConfirmPasswordChange = event => {
        this.setState({
            confirmPassword: event.target.value,
            confirmPasswordFocused: true,
            confirmPasswordInvalid: this.state.password !== event.target.value
        })
    }

    onFormSubmit(event) {
        event.preventDefault();

        axios.post(`${API_URL}/user/reset/password/confirmation/ui`,
            {
                token: this.props.token,
                password: this.state.password
            },
            header)
            .then(
                res => {
                    if (res.status === 200)
                        this.setState({ activeStep: 1 })
                },
                error => {
                    this.props.showError(error.toString());
                }
            );
    }

    render() {
        const { classes } = this.props;
        const { activeStep, password, confirmPassword } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.bar}>
                    <img src={logoIcon} alt='TookFresh' height="45" />
                </div>
                <Container maxWidth="xs" className={classes.container}>
                    {(activeStep === 0) ?
                        <div className={classes.paper}>
                            <Typography variant="h4" style={{ fontWeight: 500 }}>
                                {this.getLabel('reset-password')}
                            </Typography>
                            <form className={classes.form} onSubmit={this.onFormSubmit} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    label={this.getLabel('new-password')}
                                    autoComplete="password"
                                    autoFocus
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={password}
                                    error={this.state.passwordFocused && this.state.passwordInvalid}
                                    onChange={this.handlePasswordChange}
                                    helperText={(this.state.passwordFocused && this.state.passwordInvalid) ? this.getLabel('password-error') : ' '}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <IconButton color="inherit" size="small" disabled={!password}
                                                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                                    {this.state.showPassword ?
                                                        <img src={visibleIcon} alt='' height="24" />
                                                        :
                                                        <img src={visibleOff} alt='' height="24" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={this.getLabel('confirm-password')}
                                    type={this.state.showConfirm ? 'text' : 'password'}
                                    autoComplete="confirm-password"
                                    error={this.state.confirmPasswordFocused && this.state.confirmPasswordInvalid}
                                    onChange={this.handleConfirmPasswordChange}
                                    helperText={(this.state.confirmPasswordFocused && this.state.confirmPasswordInvalid) ? this.getLabel('password-match-error') : ' '}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <IconButton color="inherit" size="small" disabled={!confirmPassword}
                                                    onClick={() => this.setState({ showConfirm: !this.state.showConfirm })}>
                                                    {this.state.showConfirm ?
                                                        <img src={visibleIcon} alt='' height="24" />
                                                        :
                                                        <img src={visibleOff} alt='' height="24" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div style={{ marginTop: 40, width: '100%' }}>
                                    <Button variant="contained"
                                        type="submit"
                                        className={classes.button}
                                        disabled={
                                            this.state.passwordInvalid ||
                                            this.state.confirmPasswordInvalid
                                        }
                                    >
                                        {this.getLabel('change-password')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                        :
                        <div className={classes.paper}>
                            <Typography variant="h4" style={{ fontWeight: 500, marginBottom: 40 }}>
                                {this.getLabel('success-label')}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'center' }}>
                                {this.getLabel('success-message')}
                            </Typography>
                            <a className={classes.route} href="https://web.tookfresh.com"  >
                                <Button className={classes.routeButton} variant='outlined' disableRipple>
                                    {this.getLabel('return-to-main-page')}
                                </Button>
                            </a>
                        </div>}
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: ownProps.match.params.token,
    };
}


const actionCreators = {
    showError: alertActions.error,
};

export default withStyles(styles)(
    withRouter(
        injectIntl (
            connect(mapStateToProps, actionCreators)(PasswordReset)
        )
    )
);